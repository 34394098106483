import { LoadingCard } from "../../../../../../../_metronic/elements/Loading";
import { useViewAmbientContext } from "./AmbientsContext";
import NoAmbient from "./NoAmbient";
import CreatingAmbient from "./CreatingAmbient";
import ViewAmbient from "./ViewAmbient";
import DeleteAmbient from "./DeleteAmbient";

export const Ambients = () => {
    const { loading, ambientData, imageAmbientData } = useViewAmbientContext();

    if (loading) {
        return <LoadingCard />
    }

    return (
        <>
            {ambientData == null && <NoAmbient />}
            {(ambientData != null && ambientData.status === 0) && <CreatingAmbient />}
            {((ambientData != null && ambientData.status === 1) || (imageAmbientData != null && imageAmbientData.status === 1) )&& <ViewAmbient />}
        </>
    )
};