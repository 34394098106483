

export class MapSource {
    constructor(map) {
        this.map = map;
        this.geojson = null;
        this.index = {};
        this.initialized = false;
    }

    set(geojson) {
        geojson.features = geojson.features.map((feature, index) => {
            if (feature.properties.resource_type == "land") {
                return this._mapLand(feature, index);
            } else if (feature.properties.resource_type == "field") {
                return this._mapField(feature, index);
            } else if (feature.properties.resource_type == "gap") {
                return this._mapGap(feature, index);
            }
        });
        this.geojson = geojson;

        if (this.map.getSource("geojson")) {
            this.map.getSource("geojson").setData(this.geojson);
        } else {
            this.map.addSource("geojson", {
                'type': 'geojson',
                'data': this.geojson
            });
        }
        this.initialized = true;
    }

    isInitialized() {
        return this.initialized;
    }

    get() {
        return this.geojson;
    };

    getFeature(id) {
        const a = this.geojson.features.filter(f => f.properties.id == id);

        if (a.length < 1) {
            return null;
        }

        return a[0]
    };

    updateFeature(filter, func) {
        this.geojson.features = this.geojson.features.map((feature) => {
            if (filter(feature)) {
                return func(feature);
            }

            return feature;
        });
        this.map.getSource("geojson").setData(this.geojson);
    };

    _mapLand(feature, index) {
        let properties = feature["properties"];
        this.index[properties.land_id] = index;
        feature["properties"] = { 
            ...properties, 
            color: "#1982e5", 
            opacity: 0.5, 
            id: properties.land_id,
            borderWidth: 4, 
            borderOpacity: 1, 
            borderColor: '#1982e5' 
        };
        return feature;
    }

    _mapGap(feature, index) {
        let properties = feature["properties"];
        this.index[properties.gap_id] = index;
        feature["properties"] = { 
            ...properties, 
            color: "#ff4569", 
            opacity: 0, 
            id: properties.gap_id,
            borderWidth: 4, 
            borderOpacity: 0.4, 
            borderColor: '#4caf50' 
        };
        return feature;
    }

    _mapField(feature, index) {
        let properties = feature["properties"];
        this.index[properties.field_id] = index;
        feature["properties"] = { 
            ...properties, 
            id: properties.field_id,
            borderWidth: 4,
            borderOpacity: 1,
            borderColor: '#ff5722',
        };
        return feature;
    }
}