import { openAlert } from '../elements/NotificationAlert';
import axios from "axios";
import { usePayment } from '../../app/routing/Payment';
import { useTranslation } from 'react-i18next';

const MESSAGES = {
    'duplicated_crop': 'Ya hay un cultivo en la campaña asignada',
    'max_engineers_active': 'Solo se pueden agregar 500 ingenieros',
    'max_fields_active': 'Solo se pueden tener como máximo 100 campos',
    'max_gis_active': 'Solo se pueden tener como máximo 10000 lotes',
    'max_contractors_active': 'No se puede agregar mas de 5000 contratistas',
    'duplicated_report_type': 'Ya existe un reporte con esta configuración, solo se puede tener uno por tipo',
    'dates_already_updated': 'Las fechas ya se actualizaron en este día',
    'cant_create_ambient': 'El ambiente no se puede crear en este momentos',
    'ambient_exist': 'Este lote ya tiene un ambiente creada',
    'ambient_max_size_limit': 'No se puede crear una ambientación de un lote mayor a 500 hectareas',
    'ambient_min_size_limit': 'No se puede crear una ambientación de un lote menor a 10 hectareas',
    'cant_create_ambient': 'No se puede crear una ambientación en este momento',
    'anomaly_invalid_dates': 'Fechas eligidas no son validas',
    'anomaly_exists_date': 'Este lote ya tiene una mapa de anomalías en esta fecha',
    'anomaly_max_size_limit': 'No se puede crear un mapa de anomalías de un lote mayor a 500 hectareas',
    'anomaly_min_size_limit': 'No se puede crear un mapa de anomalías de un lote menor a 10 hectareas',
    'cant_create_anomaly': 'No se puede crear un mapa de anomalías en este momento',
    'missing_valid_files_in_zip': 'El zip no tiene ningun archivo valido (KML, shp o geojson)',
    'gis_file_not_valid': 'El archivo parece no contener poligonos validos',
    'missing_crop_in_campaign': 'El lote no tiene el cultivo seleccionado en la campaña actual para hacer la estimación',
    'image_dont_belong_current_campaign': 'Las fechas de las imagenes seleccionadas no pertenecen a la campaña actual',
    'not_valid_image_date': 'La fecha de las imagenes seleccionadas no son validas',
    'token_expired': 'El token para confirmar email expiró',
    'invalid_current_password': 'La contraseña actual no coincide',
    'user_email_already_exists': 'Ya existe un usuario con este email',
    'max_buy_request_per_day': 'Llego al limite diario de solicitudes de compra',

    'invalid_user': 'El email no está registrado o la contraseña no es valida',
    'not_account_email_exist': 'No existe el email registrado en la base de datos',
    'email_already_register': 'Ya existe este email en nuestra base de datos',
    'invalid_token': 'El token venció o no es valido, tiene que solicitar uno nuevo',
    'need_email_confirmation': 'Tiene que confirmar su cuenta',

    'land_max_ha': 'Un lote no puede tener mas de 500 hectareas',

    /// Payment errores
    'plan_not_enough_machines_resources': 'Su plan no permite creas más maquínas',
    'plan_not_enough_ambients_resources': 'Su plan no permite crear más ambientaciones',
    'plan_not_enough_gis_resources': 'Su plan no permite comprar mas lotes',
    'plan_not_enough_automatic_reports_resources': 'Su plan no permite automatizar más reportes',

    'land_max_size_limit': 'Un lote no puede tener mas de 500 hectareas',
    'land_min_size_limit': 'Un lote no puede tener menos de 10 hectareas',
}

export const useErrorHandler = () => {
    const payment = usePayment();
    const { t } = useTranslation();
    const func = (request, ignore=[422]) => {
        /*
        Ignore params is an array where you put the default error handler:
            - default 422
        */
        return request.catch(res => {
            if (res instanceof axios.Cancel) {
                return { ok:false, error_type: "cancel_tpoken" };
            }

            let total = {};
            const response = res.response || {'status': 500, data: {}};

            switch (response.status) {
                case 402:
                    if (ignore.indexOf(402) == -1) {
                        payment.setActive(response.data.code);
                    }
                    total = {
                        ok: false,
                        error_type: 'need_payment',
                        data: response.data
                    }
                    break;
                case 404:
                    if (ignore.indexOf(404) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'El recurso no existe o fue eliminado'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'not_found',
                        data: response.data
                    }
                    break;
                case 400:
                    if (ignore.indexOf(400) == -1) {
                        let msg = 'Los datos ingresados contienen errores';
                        
                        if (response.data.code) {
                            const translated = t("errors:"+response.data.code, MESSAGES[response.data.code])
                            if (translated) {
                                msg = translated;
                            }
                        }

                        openAlert(
                            'danger',
                            'Error',
                            msg
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'field_error',
                        data: response.data
                    };
                    break;    
                case 422:
                    if (ignore.indexOf(422) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Los datos ingresados contienen errores'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'field_error',
                        data: response.data
                    };
                    break;
                case 403:
                    if (ignore.indexOf(403) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Inicio de sesión requerido'
                        );
                        setTimeout(() => {
                            window.location.href = '/auth/login';
                        }, 100);
                    }
                    total = {
                        ok: false,
                        error_type: 'internal_error',
                        data: response.data
                    }
                    break;
                default:
                    if (ignore.indexOf(500) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Hubo un error al procesar la solicitud'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'internal_error',
                        data: response.data
                    }
                    break;
            }
            return total;
        });
    }

    const setInputError = ({ response, setFieldError }) => {
        const set = (key, data) => {
            if (Array.isArray(data)) {
                for (var z in data) {
                    set(key, data[z]);
                }
            } else {
                setFieldError(key, data);
            }
        };

        for (var z of Object.keys(response)) {
            set(z, response[z]);
        }
    };

    return {
        errorHandler: func,
        setInputError: setInputError
    };
};
