import { useState, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useViewLandContext } from "../ViewLandContext";
import { useViewImageContext } from "./ViewImageContext";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../../../services/LandService";
import "./DateSelector.style.scss";

const DateSelector = () => {
  const viewLandContext = useViewLandContext();
  const viewImageContext = useViewImageContext();
  const [selectedDate, handleDateChange] = useState(null);
  const [openedDatePicker, setOpenedDatePicker] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();

  useEffect(() => {
    if (viewLandContext.land == null) {
      return;
    }
    // Update dates
    errorHandler(LandService.datesV2(viewLandContext.land.land_id)).then(res => {
      if (res.ok) {
        viewImageContext.setDates(res.data.dates);
      }
    });
  }, [viewLandContext.land]);

  return (
    <>
      <div className="d-flex align-items-center p-5">
        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        <div className="flex-grow-1 me-2">
          <a onClick={(e) => {
            e.preventDefault();

            if (viewImageContext.loading) {
              return;
            }

            setOpenedDatePicker(true);
          }} href="#" className={`fw-bold text-gray-800 text-hover-primary fs-6 ` + (viewImageContext.loading ? 'disabled' : '')}>
            {!viewImageContext.activeLands[viewLandContext.land.land_id] || viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate == null ? t("images:date_selector") : format(
              viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate, 'dd/MM/yyyy'
            )}
          </a>
        </div>
      </div>
      {viewImageContext.activeLands[viewLandContext.land.land_id] && <DatePicker
        open={openedDatePicker}
        value={viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          let extraClass = "";
          if (selectedDate != null && selectedDate.getTime() == day.getTime()) {
            extraClass = "active";
          }
          return <div className={viewImageContext.dates.indexOf(format(day, 'yyyy-MM-dd')) != -1 ? 'date-with-data ' + extraClass : ''}>{dayComponent}</div>;
        }}
        shouldDisableDate={(date) => viewImageContext.dates.indexOf(format(date, 'yyyy-MM-dd')) == -1}
        onChange={(date) => viewImageContext.changeDate(viewLandContext.land, date)}
        onOpen={() => setOpenedDatePicker(true)}
        onClose={() => setOpenedDatePicker(false)}
        animateYearScrolling
        TextFieldComponent={() => null}
      />}
    </>
  );
};

export default DateSelector