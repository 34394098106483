import { createContext, useContext, useState, useRef } from "react";

const MapLegendContext = createContext();

export function useMapLegendContext() {
  return useContext(MapLegendContext);
}

export const MapLegendConsumer = MapLegendContext.Consumer;

export function MapLegendProvider({ children }) {
  const [basicLegend, setBasicLegend] = useState(null);
  const [ambient, setAmbient] = useState(null);
  const [enhanced, setEnhanced] = useState({});
  const [activeLand, setActiveLand] = useState(null);
  const savedLegends = useRef({});

  const handleEnhanced = (land_id) => {
    setEnhanced((prev) => {
      return { ...prev, [land_id]: !prev[land_id] };
    });
  };

  const changeBasicLegend = (land_id, legend) => {
    savedLegends.current[land_id] = {"basic": legend};

    setAmbient(null);
    setBasicLegend(legend);
    setActiveLand(land_id);
  };

  const recoverIfExists = (land_id) => {
    if (savedLegends.current[land_id]) {
      if (savedLegends.current[land_id]["basic"]) {
        changeBasicLegend(land_id, savedLegends.current[land_id]["basic"]);
      } else if (savedLegends.current[land_id]["ambient"]) {
        handleAmbient(land_id, savedLegends.current[land_id]["ambient"])
      }
    }
  };

  const deleteLegend = (land_id) => {
    savedLegends.current[land_id] = null;
    if (activeLand == land_id) {
      setAmbient(null);
      setBasicLegend(null);
      setActiveLand(null);
    }
  };

  const deleteAll = () => {
    setAmbient(null);
    setBasicLegend(null);
    setActiveLand(null);
  };

  const handleAmbient = (land_id, ambient) => {
    savedLegends.current[land_id] = {"ambient": ambient};
    setBasicLegend(null);
    setAmbient(ambient);
  };

  const value = {
    deleteAll,
    basicLegend, changeBasicLegend, deleteLegend, recoverIfExists,
    enhanced, handleEnhanced,
    ambient, handleAmbient,

    activeLand
  };

  return (
    <>
      <MapLegendContext.Provider value={value}>
        {children}
      </MapLegendContext.Provider>
    </>
  );
};