import React, { createContext, useContext, useState, useEffect } from "react";
import { EVI, Visible, NDVI } from "./Services";
import { viewImageState } from "./ViewImageState";
import PopupEvent from "./Services/PopupEvent/PopupEvent";
import GeoTIFF, { fromBlob } from "geotiff";
import { useMapLayersV2 } from "../../../../Map/MapLayersV2Context";

const ViewImageContext = createContext();

export function useViewImageContext() {
  return useContext(ViewImageContext);
}

export const ViewImageConsumer = ViewImageContext.Consumer;

export function ViewImageProvider({ children }) {
  const [hasImageActive, setImageActive] = useState(false);
  const [imageType, setImageType] = useState();
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeLands, setActiveLands] = useState({});
  const [loading, setLoading] = useState(false);
  const [openPrescription, setOpenPrescription] = useState(false);
  const [openImageAmbient, setOpenImageAmbient] = useState(false);
  const [prescriptionAmbient, setPrescriptionAmbient] = useState(null);
  const [openedAmbients, setOpenedAmbients] = useState([]);
  const mapLayers = useMapLayersV2();

  const addOpenedAmbients = (land_id) => {
    setOpenedAmbients(prev => [...prev, land_id]);
  }

  const addLand = (land) => {
    if (activeLands[land.land_id] != null || activeLands[land.land_id] != undefined) {
      return;
    }
    setActiveLands(prev => ({ ...prev, [land.land_id]: { land, imageType: "rgb", selectedDate: null } }));
  };

  const changeImageType = (land, imageType) => {
    if (loading) { return }

    viewImageState.updateImageType(land.land_id, imageType);

    setActiveLands(prev => {
      const n = { ...prev };
      n[land.land_id]["imageType"] = imageType;
      return n;
    });
  };

  const changeDate = (land, date) => {
    viewImageState.updateImageDate(land.land_id, date);

    setActiveLands(prev => {
      const n = { ...prev };
      n[land.land_id]["selectedDate"] = date;
      return n;
    });
  };

  const getLand = (land_id) => {
    if (activeLands[land_id]) {
      return activeLands[land_id];
    }

    return null;
  };

  const closeAllImages = () => {
    Object.keys(activeLands).forEach(key => {
      changeImageType(activeLands[key].land, null);
    });

    openedAmbients.forEach(land_id => {
      mapLayers.land.ambient.remove({
        landId: land_id
      });
    })

    setOpenedAmbients([]);
  };

  useEffect(() => {
    let active = false;
    Object.keys(activeLands).forEach(key => {
      if (activeLands[key].imageType != null && activeLands[key].selectedDate != null) {
        active = true;
      }
    });

    if (active || openedAmbients.length >= 1) {
      setImageActive(true);
    } else {
      setImageActive(false);
    }
  }, [activeLands, openedAmbients]);

  const addLandTiff = (landId, tiffBlob) => {
    fromBlob(tiffBlob)
      .then(tiff => tiff.getImage())
      .then(image => {
        viewImageState.addLandTiff(landId, image);
      });
  }

  const getLandTiff = (landId) => {
    return viewImageState.getLandTiff(landId);
  }

  const value = {
    dates,
    setDates,

    selectedDate,
    changeDate,
    addLandTiff,

    imageType,
    changeImageType,

    activeLands,
    addLand,

    loading,
    setLoading,

    openPrescription,
    'setOpenPrescription': (v) => {
      setOpenPrescription(v);
    },
    openImageAmbient,
    'setOpenImageAmbient': (v) => {
      setOpenImageAmbient(v);
    },
    prescriptionAmbient,
    setPrescriptionAmbient,

    getLand,

    closeAllImages,
    openedAmbients,
    addOpenedAmbients,
    hasImageActive,
    getLandTiff
  };

  return (
    <>
      <ViewImageContext.Provider value={value}>
        {children}
        <PopupEvent />
        {Object.keys(activeLands).map(landKey => {
          const { imageType, selectedDate, land } = activeLands[landKey];
          const key = `${imageType}-${selectedDate}-${land.land_id}`;
          return (
            <React.Fragment key={`image-provider-parent-${key}`}>
              {(imageType == 'evi' && selectedDate != null) && <EVI key={key} land={land} selectedDate={selectedDate} />}
              {(imageType == 'ndvi' && selectedDate != null) && <NDVI key={key} land={land} selectedDate={selectedDate} />}
              {(imageType == 'rgb' && selectedDate != null) && <Visible key={key} land={land} selectedDate={selectedDate} />}
            </React.Fragment>
          )
        })}
      </ViewImageContext.Provider>
    </>
  );
};